<template>
  <div>
    <div class="h5 fw-bold p-2 bg-light w-100 mb-3">
      LOGS
    </div>
    <div class="d-lg-flex gap-2 mb-2 pb-2 border-bottom">
      <b-col xl="2">
        <FormInputSelect
          v-model="filter.per_page"
          class="mb-3 mb-lg-0"
          :options="[
            { value: '10', label: '10' },
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
          ]" 
          label="Items per page"
        />
      </b-col>
      <b-col>
        <FormDatePicker
          v-model="filter.start_date"          
          class="mb-3 mb-lg-0"
          label="Start"
          placeholder="Select Start Datetime"
        />
      </b-col>
      <b-col>
        <FormDatePicker
          v-model="filter.end_date"          
          class="mb-3 mb-lg-0"
          label="End"
          placeholder="Select End Datetime"
        />
      </b-col>
      <b-col>
        <FormInputSelect
          v-model="filter.is_success"
          class="mb-3 mb-lg-0"
          :options="[
            { value: '', label: 'All' },
            { value: '1', label: 'Success' },
            { value: '0', label: 'Failed' },
          ]" 
          label="Status"
        />
      </b-col>
      <b-col
        xl="2"
        class="d-flex justify-content-between gap-2"
      >
        <b-button
          class="w-100"
          variant="primary"
          @click="getTxLogs"
        >
          Filter
        </b-button>
        <b-button
          class="w-100"
          variant="soft-danger"
          @click="resetForm"
        >
          Reset
        </b-button>
      </b-col>
    </div>
    <div class="d-md-flex gap-2 mb-2 pb-2 border-bottom">
      <b-col>
        <SearchBar
          class="mb-3 mb-md-0"
          placeholder="Search device by ID, EUI, or vehicle number"
          @do-search="(val) => {filter.keyword = val; getTxLogs()}"
          @reset-search="filter.keyword = null; getTxLogs()"
        />
      </b-col>
    </div>
    <div>
      <DataTable
        :fields="tableFields"
        :items="txLogs"
        :style="isLoading ? 'filter: opacity(0.4);' : ''"
        has-unit
        :allow-local-sort="false"
        @sort-changed="handleSort"
      >
        <template #cell(device_id)="{ row }">
          <span v-html="highlightString(row.item.device_id, filter.keyword)" />
        </template>
        <template #cell(device_eui)="{ row }">
          <span v-html="highlightString(row.item.device_eui, filter.keyword)" />
        </template>
        <template #cell(vehicle_number)="{ row }">
          <span v-html="highlightString(row.item.vehicle_number, filter.keyword)" />
        </template>
      
        <template #cell(created_at)="{ row }">
          <div>
            {{ format(new Date(row.item.created_at), 'dd MMM yyyy HH:mm') }}
          </div>
        </template>
        <template #cell(is_success)="{ row }">
          {{ row.item.is_success ? 'Success' : 'Failed' }}
        </template>
        <template #cell(tx_power)="{ row }">
          {{ getTxPowerDbm(row.item.tx_power_index) }}
        </template>
      </DataTable>
      <b-row class="d-md-flex mb-3 mt-3 mt-md-2 justify-content-between">
        <b-col
          md
          class="d-flex"
        >
          <div class="mx-auto mx-md-0 my-auto">
            Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} results
          </div>
        </b-col>
        <b-col
          md
          class="d-flex"
        >
          <b-pagination
            v-show="pagination.total"
            v-model="filter.page"
            :total-rows="txMeta.total"
            :per-page="Number(txMeta.per_page)"
            hide-goto-end-buttons
            prev-text="Previous"
            next-text="Next"
            class="mx-auto mx-md-0 mt-2 mt-md-0 ms-md-auto mb-0"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import FormInputSelect from '@/components/FormInputSelect.vue';
  import FormDatePicker from '@/components/FormDatePicker.vue';
  import DataTable from '@/components/DataTable.vue';
  import SearchBar from '@/components/SearchBar.vue';
  import { format } from 'date-fns';
  import { utcOffset } from '@/utils/constant';
  import { highlightString } from '@/utils/helper';

  export default {
    components: {
      FormInputSelect, FormDatePicker,
      DataTable,
      SearchBar
    },
    data() {
      return {
        tableFields: [{ 
            key: 'device_id',
            label: 'Device ID',
            class: 'text-break fit',
            sortable: true,
          }, { 
            key: 'device_eui',
            label: 'Device EUI',
            class: 'text-break fit',
            sortable: true,
          }, { 
            key: 'vehicle_number',
            label: 'Vehicle Number',
            unit: 'No. Polisi',
            class: 'text-break fit',
            sortable: true,
          }, { 
            key: 'created_at',
            label: 'Timestamp',
            unit: utcOffset,
            class: 'text-break fit',
            sortable: true,
            thStyle: {
              minWidth: '10rem',
            },
          }, { 
            key: 'is_success',
            label: 'Status',
            class: 'text-break fit',
          }, { 
            key: 'tx_power_index',
            label: 'Sent Index',
            class: 'text-break fit',
          }, { 
            key: 'tx_power',
            label: 'Tx Power',
            unit: 'dBm',
            class: 'text-break fit',
          },  { 
            key: 'is_verified',
            label: 'Is Verified',
            class: 'text-break fit',
          },  { 
            key: 'retries',
            label: 'Retries',
            class: 'text-break fit',
          }, { 
            key: 'error_message',
            label: 'Error Message',
          },
        ],
        isLoading: false,
        filter: {
          start_date: format(new Date(), 'yyyy-MM-dd')+ " 00:00",
          end_date: format(new Date(), 'yyyy-MM-dd') + " 23:59",
          is_success: '',
          order_by: 'created_at',
          direction: 'asc',
          keyword: null,
          page: 1,
          per_page: 10
        },
        txLogs: [],
        txMeta: {},
      };
    },
    computed: {
      pagination() {
        if (this.txMeta) {
          return {
            from: Number(this.txMeta.from) ?? 0,
            to: Number(this.txMeta.to) ?? 0,
            total: Number(this.txMeta.total)?? 0,
          }
        } return {
          from: 0,
          to: 0,
          total: 0,
        }
      },
    },
    mounted () {
      this.getTxLogs();
    },
    methods: {
      format, highlightString,
      async getTxLogs() {
        try {
          this.isLoading = true;
          const { data } = await this.$api.getTxPowerLogs({
            ...this.filter,
            start_date: new Date(this.filter.start_date).toISOString(),
            end_date: new Date(this.filter.end_date).toISOString(),
            order_by: this.filter.order_by
          });
          this.txLogs = data.data;
          this.txMeta = data.meta;
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      },
      
      resetForm() {
        this.filter = {
          start_date: format(new Date(), 'yyyy-MM-dd')+ " 00:00",
          end_date: format(new Date(), 'yyyy-MM-dd') + " 23:59",
          is_success: null,
          order_by: '',
          direction: '',
          keyword: null,
          page: 1,
        }
        this.getTxLogs()
      },
      
      handleSort(sort) {
        this.filter.order_by = sort.sortBy
        this.filter.direction = sort.direction
        this.filter.page = 1,
        this.getTxLogs()
      },

      getTxPowerDbm(index) {
        switch (index) {
          case 5:
            return 27
          case 6:
            return 26
          case 7:
            return 24
          case 8:
            return 22
          case 9:
            return 20
          default:
            return 0;
        }

      }
    },
  };
</script>