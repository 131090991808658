<template>
  <div>
    <div class="d-flex justify-content-between p-2 bg-light w-100 mb-3">
      <h5 class="my-auto fw-bold">
        TX POWER
      </h5>
      <VueToggles
        v-if="!isLoading"
        class="my-auto"
        :value="txPowerValue?.enabled ?? false"
        :width="100"
        checked-text="ENABLED"
        unchecked-text="DISABLED"
        checked-bg="#3577F1"
        @click="updateTxSettings"
      />
      <div
        v-else
        class="d-flex my-auto py-1 px-2"
        style="background-color: #CFDEFC; color: #2654AB; border-radius: 50px; width: 100px; height: 25px;"
      >
        <span class="me-2">
          LOADING 
        </span>
        <i class="bx bx-loader my-auto" />
      </div>
    </div>
    <div class="mb-3">
      <b-card-body
        class="rounded"
        style="border: 1px #CED4DA solid;"
      >
        <table class="table-borderless">
          <tbody>
            <tr>
              <td class="py-1">
                Downlink Port
              </td>
              <td class="py-1 px-3">
                :
              </td>
              <td class="py-1">
                {{ txPowerValue?.downlink_port }}
              </td>
            </tr>
            <tr>
              <td class="py-1">
                Tx Power Index
              </td>
              <td class="py-1 px-3">
                :
              </td>
              <td class="py-1">
                {{ txPowerValue?.txpower_index }}
              </td>
            </tr>
            <tr>
              <td class="py-1">
                Tx Power
              </td>
              <td class="py-1 px-3">
                :
              </td>
              <td class="py-1">
                {{ txPowerDbm }} dBm
              </td>
            </tr>
          </tbody>
        </table>
      </b-card-body>
      <b-button-group class="w-100">
        <b-button
          variant="primary"
          data-bs-toggle="modal"
          href="#tx-form-modal"
        >
          Edit TX Power Setting
        </b-button>
      </b-button-group>
    </div>
    <TxPowerLogs />
    <TxPowerFormModal
      :tx-power="txPowerValue"
      @update-data="getTxSettings"
    />
  </div>
</template>

<script>
  import { VueToggles } from "vue-toggles";
  import TxPowerFormModal from './SettingsTxPowerFormModal.vue';
  import TxPowerLogs from './SettingsTxPowerLogs.vue';

  import { mapState } from 'vuex';

  export default {
    components: {
      TxPowerFormModal,
      TxPowerLogs,
      VueToggles
    },
    data() {
      return {
        title: "My Profile",
        items: [{
            text: "Settings",
            href: "/settings",
          },
        ],
        tableFields: [
          { 
            key: 'name',
            label: 'Name',
            class: 'text-break fit',
            sortable: true,
          },
          { 
            key: 'description',
            label: 'Description'
          }
        ],
        isLoading: false,
        txSettings: {},
      };
    },
    computed: {
      ...mapState('auth', ['profile']),

      txPowerValue() {
        return this.txSettings?.values
      },

      txPowerDbm() {
        switch (this.txPowerValue?.txpower_index) {
          case 5:
            return 27
          case 6:
            return 26
          case 7:
            return 24
          case 8:
            return 22
          case 9:
            return 20
          default:
            return 0;
        }
      }
    },
    mounted () {
      this.getTxSettings();
    },
    methods: {
      async getTxSettings() {
        try {
          this.isLoading = true;
          const { data } = await this.$api.getTxPowerSettings();
          this.txSettings = data.data;
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      },
      async updateTxSettings() {
        try {
          this.isLoading = true;
          await this.$api.updateTxPowerSettings({
            ...this.txPowerValue,
            enabled: !this.txPowerValue.enabled
          });
          this.getTxSettings()
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      },
    },
  };
</script>