<template>
  <div>
    <b-input-group
      class="mb-md-0 mb-3"
    >
      <template #prepend>
        <b-input-group-text
          class="prepend-outline-secondary"
          style="background-color: transparent;"
        >
          {{ label }}
        </b-input-group-text>
      </template>
      <flat-pickr
        v-model="localValue"
        name="date"
        :config="configDatePicker"
        class="form-control has-prepend has-icon-append bg-none"
        :placeholder="placeholder"
        @on-change="setSelectedDate"
      />
      <div
        class="d-flex icon-input-append h-100 px-3"
        data-toggle
      >
        <span
          aria-hidden="true"
          class="sr-only"
        >Toggle</span>
        <i class="my-auto las la-calendar" />
      </div>
    </b-input-group>
  </div>
</template>

<script>
import { format } from 'date-fns';
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  components: {
    flatPickr,
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'Date'
    },
    placeholder: {
      type: String,
      default: 'Select Date'
    },
    enableTime: {
      type: Boolean,
      default: true
    },
    altFormat: {
      type: String,
      default: 'j M Y  H:i'
    }
  },
  data() {
    return {
      localValue: '',
    }
  },
  computed: {
    configDatePicker() {
      let endToday = new Date();
      endToday.setHours(23, 59, 0, 0);
      return {
        mode: 'single',
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: this.altFormat,
        altInput: true,
        maxDate: format(endToday, "yyyy-MM-dd HH:mm"),
        disableMobile: 'true',
        enableTime: this.enableTime,
        time_24hr: true,
      };
    },
  },
  watch: {
    modelValue: {
      immediate: false,
      handler() {
        this.localValue = this.modelValue
      }
    },
  },
  mounted () {
    this.localValue = this.modelValue
  },
  methods: {
    setSelectedDate(selectedDates, dateStr) {
      if(selectedDates.length == 2) {
        const datesArr = [
          format(selectedDates[0], 'yyyy-MM-dd'),
          format(selectedDates[1], 'yyyy-MM-dd')
        ]
        this.$emit('update:modelValue', datesArr)
      } else {
        this.$emit('update:modelValue', dateStr)
      }
    }
  },
}
</script>