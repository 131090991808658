<template>
  <b-modal
    id="tx-form-modal"
    v-model="showModal"
    title="Edit TX Power Settings"
    centered
    hide-footer
    @shown="resetForm"
  >
    <b-form
      class="mt-2"
      @submit.prevent="onSubmit"
    >
      <div class="mb-3">
        <VueToggles
          v-model="form.enabled"
          class="my-auto"
          :width="100"
          checked-bg="#3577F1"
          checked-text="ENABLED"
          unchecked-text="DISABLED"
        />
      </div>
      <b-form-group
        class="mb-3 required"
        label="Downlink Port"
        label-for="input-downlink"
      >
        <b-form-input
          id="input-downlink"
          v-model="form.downlink_port"
          type="number"
          placeholder="Enter port"
          :state="v$.form.downlink_port.$dirty ? (!v$.form.downlink_port.$error? null : false) : null"
        />
        <b-form-invalid-feedback
          v-if="v$.form.downlink_port.$error"
          class="d-inline"
        >
          <span
            v-for="(error, index) in v$.form.downlink_port.$errors"
            :key="index"
          >
            {{ error.$message + ' ' }}
          </span>
        </b-form-invalid-feedback>
        <div
          v-else
          class="small mt-1 text-muted"
        > 
          Downlink port is required
        </div>
      </b-form-group>

      <b-form-group class="mb-3">
        <div
          class="mb-2"
          style="font-weight: 500;"
        >
          Tx Power <span class="text-danger">*</span>
        </div>
        <div
          v-for="(option, index) in txPowerOptions"
          :key="index"
          :class="!index ? 'border-top border-bottom' : 'border-bottom'"
          style="cursor: pointer;"
          @click="form.txpower_index = option.value"
        >
          <div class="d-flex">
            <i
              class="my-auto"
              style="font-size: x-large;"
              :class="form.txpower_index === option.value ? `bx bx-radio-circle-marked` : `bx bx-radio-circle`"
            />
            <span class="my-auto">
              {{ option.text }}
            </span>
          </div>
        </div>
        <div class="small mt-1 text-muted"> 
          Please select Tx Power
        </div>
      </b-form-group>

      <div class="d-flex mt-4">
        <b-button
          class="ms-auto"
          type="submit"
          variant="primary"
        >
          Save
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { VueToggles } from "vue-toggles";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useToast } from 'vue-toastification';
import ToastDefault from "@/components/ToastDefault.vue";

export default {
  components: {
    VueToggles,
  },
  props: {
    txPower: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  setup() {
    const toast = useToast();
    return { 
      toast,
      v$: useVuelidate() 
    };
  },
  validations() {
    return {
      form: {
        downlink_port: {
          required: helpers.withMessage("Downlink port is required.", required)
        },
        txpower_index: {
          required: helpers.withMessage("Tx Power Index is required.", required),
        },
      },
    }
  },
  data() {
    return {
      form: {
        downlink_port: null,
        txpower_index: null,
        enabled: false,
      },
      txPowerOptions: [
        { text: '27 dBm', value: 5 },
        { text: '26 dBm', value: 6 },
        { text: '24 dBm', value: 7 },
        { text: '22 dBm', value: 8 },
        { text: '20 dBm', value: 9 },
      ],
      showModal: false,
      errors: {},
    };
  },
  methods: {
    onSubmit() {
      console.log(this.form)
      this.v$.$touch();
      if (this.v$.$invalid) {
        return
      }
      this.submitForm();
    },
    resetForm() {
      this.v$.$reset()
      this.resetErrors()
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.txPower[key];
      });
    },
    async submitForm() {
      try {
        await this.$api.updateTxPowerSettings(this.form);
        this.closeModal();
        this.$emit('update-data', 'Edited');
        // toast
        const toastContent = {
          component: ToastDefault,
          props: {
            variant: 'secondary',
            text: 'Updated Successfully',
            icon: 'ri-check-double-line'
          },
        };
        this.toast(toastContent);
        this.closeModal();
      } catch (error) {
        console.log(error);
        this.errors = error.response.data.errors;
        // toast
        const toastContent = {
          component: ToastDefault,
          props: {
            variant: 'danger',
            text: 'Process Failed',
          },
        };
        this.toast(toastContent);
      }
    },
    closeModal() {
      this.showModal = false;
      this.resetForm();
    },
    hasErrors(key) {
      if (this.errors[key]) {
        return true;
      }
      return false;
    },
    getErrors(key) {
      if (this.hasErrors(key)) {
        return this.errors[key].join(", ");
      }
      return "";
    },
    resetErrors() {
      this.errors = {}
    }
  },
};
</script>