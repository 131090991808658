<template>
  <Layout :items="items">
    <PageHeader
      title="Settings"
      class="mb-0"
      style="height: 54px;"
    />
    <b-row style="margin-left: -1.5rem; margin-right: -1.5rem">
      <b-col
        md="3"
        xl="2"
        class="d-none d-md-block border-2 border-end map-container"
      >
        <b-nav
          vertical
          class="mt-3"
        >
          <router-link to="/profile">
            <b-nav-item
              class="dropdown-item px-0 border-top border-bottom"
              style="cursor: pointer;"
            >
              <div class="d-flex justify-content-between h5 fw-bold mb-0">
                <div>PROFILE</div>
                <i class="bx bx-chevron-right ms-auto" />
              </div>
            </b-nav-item>
          </router-link>
          <b-nav-item
            active
            class="dropdown-item px-0 border-top border-bottom"
            style="cursor: pointer;"
          >
            <div class="d-flex justify-content-between h5 fw-bold mb-0">
              <div>TX POWER</div>
              <i class="bx bx-chevron-right ms-auto" />
            </div>
          </b-nav-item>
        </b-nav>
      </b-col>
      <b-col 
        md="9"
        xl="10"
      >
        <div class="my-3">
          <SettingsTxPower />
        </div>
      </b-col>
    </b-row>
  </Layout>
</template>

<script>
  import Layout from "@/layouts/main.vue";
  import PageHeader from "@/components/PageHeader";

  import SettingsTxPower from './SettingsTxPower.vue';

  import { mapState } from 'vuex';

  export default {
    components: {
      Layout, PageHeader, SettingsTxPower,
    },
    data() {
      return {
        title: "My Profile",
        items: [{
            text: "Settings",
            href: "/settings",
          },
        ],
        selectedPage: 'profile',
      };
    },
    computed: {
      ...mapState('auth', ['profile']),
    },
    mounted () {
      if(!this.profile.is_superadmin) {
        this.$router.push('/profile')
      };
    },
  };
</script>